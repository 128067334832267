import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Container,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Pagination,
  Paper
} from '@mui/material';

// routes
import { paths } from 'src/routes/paths';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import { useTable } from 'src/components/table';
import { baseURL } from 'src/config-global';
import axios from 'src/utils/axios';
import { LoadingScreen } from 'src/components/loading-screen';
import Iconify from 'src/components/iconify';

export default function LogsView({ menu }) {
  const table = useTable();
  const settings = useSettingsContext();
  const confirm = useBoolean();
  const [logsData, setLogsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  // Pagination State
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const perPage = 15;

  useEffect(() => {
    fetchLogs(currentPage);
  }, [currentPage]);

  // Fetch logs with pagination
  const fetchLogs = async (page = 1) => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseURL}/user-request-logs?page=${page}`);
      const responseData = response?.data?.data;

      setLogsData(responseData?.data || []);
      setTotalPages(responseData?.last_page || 1);
    } catch (error) {
      console.error('Error fetching logs:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle page change
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // Render logs with JSON parsing
  function parseLogs(logEntries) {
    return logEntries.map((log) => ({
      timestamp: log.time_stamp,
      level: log.level.toUpperCase(),
      message: log.message,
      raw: log.data ? JSON.stringify(log.data, null, 2) : '',
    }));
  }

  const parsedLogs = parseLogs(logsData);

  // Filter logs based on search input
  const filteredLogs = parsedLogs.filter((log) => {
    const searchLower = searchQuery.toLowerCase();
    return (
      (log.timestamp && log.timestamp.toLowerCase().includes(searchLower)) ||
      (log.level && log.level.toLowerCase().includes(searchLower)) ||
      (log.message && log.message.toLowerCase().includes(searchLower)) ||
      (log.raw && log.raw.toLowerCase().includes(searchLower))
    );
  });

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        gutter={false}
        heading="Logs"
        links={[{ name: '', href: paths.dashboard.user.list }]}
        sx={{ mb: { xs: 3, md: 3 } }}
      />

      <Card sx={{ p: 3, boxShadow: 3, borderRadius: 2, backgroundColor: '#f9fafb' }}>
        {/* Search Bar */}
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
          <TextField
            variant="outlined"
            placeholder="Search logs..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="mdi-light:magnify" width={20} />
                </InputAdornment>
              ),
            }}
            sx={{
              backgroundColor: '#fff',
              borderRadius: 2,
              boxShadow: 1,
            }}
          />
        </Box>

        {/* Logs List - Scrollable Container */}
        <Paper
          sx={{
            // maxHeight: '60vh',
            // overflowY: 'auto',
            padding: 2,
            borderRadius: 2,
            background: '#fff',
          }}
        >
          {filteredLogs.length === 0 ? (
            <Typography align="center" color="text.secondary">
              No logs found.
            </Typography>
          ) : (
            filteredLogs.map((log, index) => (
              <Card
                key={index}
                sx={{
                  mb: 1.5,
                  p: 2,
                  // background: '#f4f4f4',
                  borderRadius: 2,
                  borderLeft: `4px solid ${log.level === 'ERROR' ? 'red' : '#637381'}`,
                }}
              >
                {log.timestamp && (
                  <Typography variant="body2" fontWeight="bold">
                    Time: <span style={{ fontWeight: 400 }}>{log.timestamp}</span>
                  </Typography>
                )}
                {log.level && (
                  <Typography variant="body2" fontWeight="bold">
                    Level: <span style={{ fontWeight: 400, textTransform: 'capitalize' }}>{log.level}</span>
                  </Typography>
                )}
                {log.message && (
                  <Typography variant="body2" fontWeight="bold">
                    Message: <span style={{ fontWeight: 400 }}>{log.message}</span>
                  </Typography>
                )}
                {log.raw && (
                  <Typography variant="body2" fontWeight="bold">
                    Data:
                    <Box
                      component="pre"
                      sx={{
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                        background: '#eef2f7',
                        p: 1,
                        mt: 1,
                        borderRadius: 1,
                        fontSize: 12,
                        fontFamily: 'monospace',
                      }}
                    >
                      {log.raw}
                    </Box>
                  </Typography>
                )}
              </Card>
            ))
          )}
        </Paper>

        {/* Pagination Controls */}
        <Box display="flex" justifyContent="center" mt={3}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            size="large"
            // sx={{ background: '#fff', borderRadius: 2, boxShadow: 1, p: 1 }}
          />
        </Box>
      </Card>

      {/* Confirmation Dialog */}
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure you want to delete <strong>{table.selected.length}</strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </Container>
  );
}

LogsView.propTypes = {
  menu: PropTypes.array,
};
