import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { LoadingScreen } from 'src/components/loading-screen';
import LogsView from 'src/sections/logs/view/logs-list-view';
// sections

// ----------------------------------------------------------------------

export default function LogsPage() {
  const [menu, setmenu] = useState([
   
  ]);

  const [loading, setLoading] = useState(false);
  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>

      {loading ? <LoadingScreen /> : <LogsView menu={menu}    />}
    </>
  );
}
